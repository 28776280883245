import { useRouter } from 'next/router';
import { useSystemAlerts } from '@hooks';
import * as React from 'react';
import { styled } from '@stitches';
import { keyframes } from '@stitches/react';

const marquee = keyframes({
  from: { transform: 'translate(0, 0)' },
  to: { transform: 'translate(-100%, 0)' },
});

const SystemAlertWrapper = styled('div', {
  paddingTop: 4,
  paddingBottom: 4,
  fontFamily: '$regular',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  fontWeight: '100',
  
  variants: {
    severity: {
      info: {
        color: 'black',
        backgroundColor: '$brand',
      },
      warning: {
        color: 'black',
        backgroundColor: 'orange',
      },
      error: {
        backgroundColor: 'red',
      },
    },
  },
});

const SystemAlertText = styled('span', {
  display: 'inline-block',
  paddingLeft: '100%',
  animation: `${marquee} 40s linear infinite`,
  transform: 'translate(100%, 0)',
});

export const SystemAlerts: React.FC = () => {
  const router = useRouter();
  const { systemAlerts, isLoading, error } = useSystemAlerts(router.locale);

  if (isLoading || error || !systemAlerts) return null;

  return <SystemAlertWrapper severity={systemAlerts.severity}>
    <SystemAlertText>
      {systemAlerts.title && `${systemAlerts.title} ⚠️ `}
      {systemAlerts.message}
    </SystemAlertText>
  </SystemAlertWrapper>;
};